<template>
  <div>
    <ListingLayout
      :is-loading="isLoading"
      :meta="meta"
      :headers="_headers"
      :items="list"
      :filter-names="['search']"
      search-label="search"
      @filterOption="onFiltersChange"
    >
      <template v-slot:actions="{ item }">
        <v-icon
          v-if="$admin.can('batteries.edit')"
          class="mr-2"
          small
          @click="editItem(item)"
        >
          mdi-pencil
        </v-icon>
        <v-icon
          v-if="$admin.can('batteries.delete')"
          class="mr-2"
          small
          @click="deleteItem(item)"
        >
          mdi-delete
        </v-icon>
      </template>

      <!-- add Filters -->
      <!-- <template #addFilters>
        <v-col
          cols="3"
          sm="3"
        >
          <v-text-field
            v-model="subFilters.search"
            dense
            clearable
            :label="$t('search')"
            class=""
          />
        </v-col>
      </template> -->
      <!-- add Filters -->

      <!-- Action Modals -->
      <template #actionModals>
        <div>
          <create-modal
            v-if="$admin.can('batteries.create')"
            :filter-options="options"
          />
          <edit-modal
            v-if="$admin.can('batteries.edit') && editModal"
            :is-visible-dialog="editModal"
            :toggle-edit="toggleEditModal"
            :battery="selectedRow"
          />
          <confirmation-modal
            v-if="$admin.can('batteries.delete')"
            :is-visible="deleteModal"
            :toggle="toggleDeleteModal"
            :row="selectedRow"
          />
        </div>
      </template>
      <!-- Action Modals -->
    </ListingLayout>
  </div>
</template>

<script>
import { ListingLayoutMixins } from "@/mixins/ListingLayoutMixins";
import { mapGetters } from "vuex";
// import { debounce } from "lodash";
export default {
  components: {
    ListingLayout: () => import("@/layouts/section/ListingLayout.vue"),
    CreateModal: () => import("./dialogs/CreateBatteryModal.vue"),
    EditModal: () => import("./dialogs/EditBattery.vue"),
    ConfirmationModal: () =>
      import("@/components/Modals/ConfirmationModal.vue"),
  },
  mixins: [ListingLayoutMixins],

  data() {
    return {
      filters: {},
      subFilters: {},

      headers: [
        {
          text: this.$t("id"),
          align: "left",
          sortable: true,
          value: "id",
          // width: "5%",
        },
        {
          text: this.$t("tenant"),
          align: "left",
          sortable: false,
          value: "tenant_name",
          // width: "15%",
          showAdmin: true,
        },
        {
          text: this.$t("battery_make"),
          align: "left",
          sortable: false,
          value: "battery_make_name",
          // width: "15%",
        },
        {
          text: this.$t("battery_model"),
          align: "left",
          sortable: false,
          value: "battery_model_name",
          // width: "10%",
        },
        {
          text: this.$t("battery_type"),
          align: "left",
          sortable: false,
          value: "battery_type_name",
        },
        {
          text: this.$t("serial_no"),
          align: "left",
          sortable: false,
          value: "serial_no",
        },
        {
          text: this.$t("bms_id"),
          align: "left",
          sortable: false,
          value: "flespi_bms_id",
        },
        {
          text: this.$t("assigned"),
          align: "left",
          sortable: false,
          value: "assigned",
        },
        ...(this.$admin.hasAccessTo("batteries.delete") ||
          this.$admin.hasAccessTo("batteries.edit")
          ? [
            {
              text: this.$t("actions"),
              align: "left",
              sortable: false,
              value: "actions",
            },
          ]
          : []),
      ],
    };
  },
  computed: {
    ...mapGetters({
      authUser: "getUser",
      isLoading: "batterries/isLoadingBatterries",
      meta: "batterries/meta",
      list: "batterries/batterriesList",
    }),
    _headers() {
      // computed headers for showing columns to role based
      return this.headers.filter(
        (header) =>
          header.showAdmin === undefined ||
          (header.showAdmin != undefined &&
            header.showAdmin === this.authUser.isAdmin)
      );
    },
  },
  watch: {
    options() {
      this.fetchData();
    },
  },
  async mounted() {
    await this.$store.dispatch("batteryTypes/list");
  },
  beforeDestroy() {
    const params = {
      name: "batterries",
      filters: { ...this.options },
    };
    this.$store.commit("redirect/SET_FILTERS", params);
  },
  methods: {
    async fetchData() {
      await this.$store.dispatch("batterries/list", this.options).then(() => {
        if (this.options.page > (this.meta?.lastPage || 1)) {
          this.options.page = 1;
        }
      });
    },

    // Confirmation of Delete Row
    async deleteSelectedRow(row) {
      try {
        await this.$store.dispatch("batterries/destroy", row);
        this.$store.dispatch("alerts/success", this.$t("deleted_successfully"));
        if (this.list.length === 0 && this.meta.lastPage == this.options.page) {
          this.options.page -= 1; // Move to the previous page
        }
        this.toggleDeleteModal("close");
        this.fetchData();
      } catch (error) {
        this.$store.dispatch("alerts/error", error.response?.data?.message);
      }
    },
  },
};
</script>